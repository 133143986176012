import { signalableFactory as actionableFactory } from './ui.adapter';

export class InitializeLayoutContainerSignal extends actionableFactory.create<
  'Initialize Layout Container Signal',
  Record<string, never>
>('Initialize Layout Container Signal') {}

export class ShowHideHeaderFilterInformationSignal extends actionableFactory.create<
  'Show Hide Header Filter Information Signal',
  Record<string, never>
>('Show Hide Header Filter Information Signal') {}

export class ShowFiltersPanelSignal extends actionableFactory.create<
  'Show Filters Panel Signal',
  { show: boolean }
>('Show Filters Panel Signal') {}

export class ShowMessagingPanelSignal extends actionableFactory.create<
  'Show Messaging Panel Signal',
  Record<string, never>
>('Show Messaging Panel Signal') {}

export class ShowProductPanelSignal extends actionableFactory.create<
  'Show Product Panel Signal',
  { show: boolean }
>('Show Product Panel Signal') {}

export class ToggleFiltersPanelSignal extends actionableFactory.create<
  'Toggle Filters Panel Signal',
  Record<string, never>
>('Toggle Filters Panel Signal') {}

export class ToggleProductPanelSignal extends actionableFactory.create<
  'Toggle Product Panel Signal',
  Record<string, never>
>('Toggle Product Panel Signal') {}
